const initialState = {
  data: null,
  loading: false,
  allTransactions: [],
  transaction: {},
};

const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DATA":
      return {
        ...state,
        allTransactions: action.allTransactions,
      };
    case "ADD_TRANSACTION":
      return {
        ...state,
        allTransactions: [...state.allTransactions, action.transaction],
      };
    case "SET_LOADING":
      return { ...state, loading: action.loading };

    case "SET_ERROR":
      return { ...state, error: action.error };

    case "SET_MESSAGE":
      return { ...state, message: action.message };

    default:
      return { ...state };
  }
};

export default transactionReducer;
